<template>
  <div class="file-wrapper">
    <input
      type="file"
      name="file"
      multiple="True"
      ref="fileInput"
      @change="handleFileInput"
    />
    <icon-upload @click="selectFile" />
    <p class="mt-2 ml-2">{{ selectedFile }}</p>
  </div>
</template>

<script>
import { ref } from 'vue';
import IconUpload from '@/components/Icons/IconUpload.vue';
export default {
  components: {
    IconUpload
  },
  props: {
    name: {
      type: String,
      default: 'name'
    }
  },
  setup() {
    const fileInput = ref();
    const selectedFile = ref();

    function selectFile() {
      fileInput.value.click();
    }

    function handleFileInput(e) {
      const files = e.target.files;
      selectedFile.value = files[0].name;
    }

    return {
      fileInput,
      selectedFile,
      selectFile,
      handleFileInput
    };
  }
};
</script>

<style lang="scss">
.file-wrapper {
  position: relative;
  text-align: center;
  display: table-cell;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
}
.file-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
</style>
