<template>
  <div class="action form-input">
    <Button
      class="p-button-danger p-mr-3 bg-teal-500 font-semibold text-white loading-btn rounded p-mt-4"
      @click="$emit('back')"
    >
      <span>{{ i18n.$t('Cancel') }}</span>
    </Button>
    <Button
      class="bg-teal-500 font-semibold text-white loading-btn rounded p-mt-4"
      @click="$emit('update', true)"
    >
      <!-- <spinner v-if="isLoading" color="white" /> -->
      <span>{{ i18n.$t('Update') }}</span>
    </Button>
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
export default {
  setup() {
    const { i18n } = useI18n();
    return {
      i18n
    };
  }
};
</script>

<style lang="scss">
.action {
  flex-direction: row !important;
  .loading-btn {
    width: 120px;
    height: 42px;
  }
  span {
    width: 100%;
  }
}
</style>