<template>
  <div class="p-mt-4">
    <ckeditor
      :editor="editor"
      v-model="editorData"
      :config="editorConfig"
    ></ckeditor>
    <Button @click="onSave" class="p-mt-3">{{ i18n.$t('Save') }}</Button>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import List from '@ckeditor/ckeditor5-list/src/list';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import useI18n from '@/plugins/useI18n';
import { ref } from 'vue';

export default {
  props: {
    content: { type: String, default: '' },
    onSaved: { type: Function }
  },
  data: () => ({
    editor: ClassicEditor,
    editorConfig: {
      // The configuration of the editor.
      plugins: [Essentials, List, CodeBlock],
      toolbar: {
        items: ['codeBlock', 'undo', 'redo']
      },
      codeBlock: {
        languages: [{ language: 'css', label: 'CSS' }]
      }
    }
  }),
  created() {},
  emits: ['update:modelValue'],
  methods: {
    onSave() {
      const startTag = '<pre><code class="language-css">';
      const endTag = '</code></pre>';
      const startIdx = this.editorData.indexOf(startTag);
      const endIdx = this.editorData.indexOf(endTag);
      const content = this.editorData.substring(
        startTag.length + startIdx,
        endIdx
      );
      this.$emit('update:modelValue', content);
      this.onSaved();
    }
  },
  setup(props) {
    const editorData = ref(props.content);
    const { i18n } = useI18n();
    return { i18n, editorData };
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  &.file-upload {
    flex-direction: row !important;
    align-items: center;
  }
}
</style>
