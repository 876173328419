<template>
  <div class="p-mt-4">
    <ckeditor
      :editor="editor"
      v-model="editorData"
      :config="editorConfig"
    ></ckeditor>
    <Button @click="onSave" class="p-mt-4">{{ i18n.$t('Save') }}</Button>
  </div>
  <!-- <input type="file" name="fileToUpload" accept="text/html/*" id="fileToUpload"  @change="onFileChanged"> -->
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import useI18n from '@/plugins/useI18n';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';
import { ref } from 'vue';

export default {
  props: {
    content: { type: String, default: '' },
    modelValue: {
      type: String,
      default: ''
    },
    onSaved: { type: Function }
  },
  data: () => ({
    editor: ClassicEditor,
    previewData: '',
    editorConfig: {
      // The configuration of the editor.
      plugins: [
        Essentials,
        UploadAdapter,
        Autoformat,
        Bold,
        Italic,
        BlockQuote,
        EasyImage,
        Heading,
        Image,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        Link,
        List,
        Paragraph,
        Alignment,
        Base64UploadAdapter,
        CodeBlock,
        HtmlEmbed
      ],
      toolbar: {
        items: [
          'htmlEmbed',
          'heading',
          '|',
          'alignment', // <--- ADDED
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'imageUpload',
          'blockQuote',
          //  'codeBlock',
          'undo',
          'redo'
        ]
      },
      image: {
        toolbar: [
          'imageStyle:full',
          'imageStyle:side',
          '|',
          'imageTextAlternative'
        ]
      },
      codeBlock: {
        languages: [
          { language: 'css', label: 'CSS' },
          { language: 'javascript', label: 'JavaScript' },
          { language: 'html', label: 'HTML' }
        ]
      }
    }
  }),
  created() {},
  methods: {
    onSave() {
      this.previewData = this.editorData;
      this.$emit('update:modelValue', this.editorData.toString());
      this.onSaved();
    },
    onFileChanged(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = (evt) => {
        this.editorData = evt.target.result;
      };
    }
  },
  setup(props) {
    const editorData = ref(props.content);
    const { i18n } = useI18n();
    return { i18n, editorData };
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  &.file-upload {
    flex-direction: row !important;
    align-items: center;
  }
}
.ck.ck-content.ck-editor__editable {
  min-height: 600px;
}
</style>
<style>
.ck-editor__editable {
  min-height: 500px;
}
</style>
