<template>
  <div class="p-grid p-fluid">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="p-mt-4 p-mb-4"
    />
    <div class="p-col-12">
      <h3 class="font-bold text-3xl text-left">
        {{ i18n.$t('Update my company') }}
      </h3>
      <TabView v-model:activeIndex="currentTab">
        <TabPanel :header="i18n.$t('Principal')">
          <Principal
            :showResult="showResult"
            :isError="isError"
            :isLoading="isLoading"
            :agency="agency"
            @back="goToBack"
            @submit="onSave"
          />
        </TabPanel>
        <TabPanel :header="i18n.$t('Category')">
          <Category @back="goToBack" @submitCat="onSaveCategory" />
        </TabPanel>
        <TabPanel :header="i18n.$t('Prestations')">
          <Prestations :agency="agency" @back="goToBack" @submit="onSave" />
        </TabPanel>
        <TabPanel :header="i18n.$t('Pratiques')">
          <Pratiques :agency="agency" @back="goToBack" @submit="onSave" />
        </TabPanel>
        <TabPanel :header="i18n.$t('Details')">
          <Details :agency="agency" @back="goToBack" @submit="onSave" />
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Spinner from '../../components/UI/Spinner.vue';
import FileInput from '../../components/UI/FileInput.vue';
import SelectAddress from '../../components/Register/SelectAddress.vue';
import useI18n from '@/plugins/useI18n';
import InputGroup from '../../components/UI/InputGroup';
import LogoUpload from '@/components/Register/LogoUpload';
import Principal from '@/components/Agency/edit/Principal';
import Category from '@/components/Agency/edit/Category';
import Prestations from '@/components/Agency/edit/Prestations';
import Pratiques from '@/components/Agency/edit/Pratiques';
import Details from '@/components/Agency/edit/Details';
import {
  UPDATE_AGENCY,
  FETCH_AGENCY,
  GET_AGENCY_MODEL
} from '../../store/types';
import Currency from '../../components/UI/Currency.vue';
import { API } from '@/plugins/api';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'my agency', to: '/agency' }]
  }),
  components: {
    Spinner,
    FileInput,
    SelectAddress,
    InputGroup,
    Currency,
    LogoUpload,
    Principal,
    Category,
    Prestations,
    Pratiques,
    Details
  },
  setup() {
    const { i18n } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const showResult = ref(false);
    const isError = ref(false);
    const isLoading = ref(false);
    const agency = computed(() => store.getters[GET_AGENCY_MODEL]);
    const paramsCurrentTab = route.params.currentTab
      ? parseInt(route.params.currentTab)
      : 0;
    const currentTab = ref(parseInt(paramsCurrentTab));
    const api = new API();

    store.dispatch(FETCH_AGENCY);

    async function onSave(item) {
      isLoading.value = true;
      showResult.value = false;
      isError.value = false;

      item.brand_id = JSON.stringify(item.brand_id);
      item.tariffs_ids = JSON.stringify(item.tariffs_ids);
      item.skills_ids = JSON.stringify(item.skills_ids);
      item.payments_ids = JSON.stringify(item.payments_ids);
      item.pratiques_ids = JSON.stringify(item.pratiques_ids);
      item.diplomes_ids = JSON.stringify(item.diplomes_ids);
      try {
        await store.dispatch(UPDATE_AGENCY, item);
        router.push({
          name: 'agency',
          params: { currentTab: currentTab.value }
        });

        showResult.value = true;
        isError.value = false;
      } catch {
        showResult.value = true;
        isError.value = true;
      }
      isLoading.value = false;
    }
    function onSaveCategory(data) {
      data.routePath['params'] = { currentTab: currentTab.value };
      api.update(
        'organisation/category',
        'Categories',
        data.id,
        data.payload,
        data.routePath
      );
    }
    function goToBack() {
      router.push({
        name: 'agency',
        params: { currentTab: currentTab.value }
      });
    }

    return {
      i18n,
      showResult,
      isError,
      isLoading,
      onSave,
      agency,
      currentTab,
      onSaveCategory,
      goToBack
    };
  }
};
</script>
