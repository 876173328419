<template>
  <div>
    <TreeSelect
      :treeValue="categoryTree"
      name="categories"
      :checked="selectedItems"
      :label="i18n.$t('Select categories')"
      :defaultVal="false"
      @selectedItems="selectedItems = $event"
    />
    <div v-if="showResult" class="mt-4">
      <p v-if="isError" class="font-semibold text-red-600">
        {{ i18n.$t('Oops, something went wrong!') }}
      </p>
      <p v-if="!isError" class="font-semibold text-green-600">
        {{ i18n.$t('Saved successfully') }}
      </p>
    </div>
    <Action @update="onSave" @back="$emit('back')" />
  </div>
</template>


<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  FETCH_AGENCY,
  GET_AGENCY_MODEL,
  FETCH_ALL_CATEGORIES,
  GET_CATEGORY_TREE,
  GET_SELECTED_CARTEGORY_TREE_VALUE,
  GET_USERDATA,
  FETCH_CATEGORY_PARENTS
} from '@/store/types';
import Spinner from '@/components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
import TreeSelect from '@/components/UI/TreeSelect';
import Action from './Action.vue';
export default {
  components: {
    Spinner,
    TreeSelect,
    Action
  },
  setup(props, { emit }) {
    const store = useStore();
    const { i18n } = useI18n();
    const agency = computed(() => store.getters[GET_AGENCY_MODEL]);
    const categoryTree = computed(() => store.getters[GET_CATEGORY_TREE]);

    const selectedTreeValue = computed(
      () => store.getters[GET_SELECTED_CARTEGORY_TREE_VALUE]
    );

    const selectedItems = ref([]);

    for (const key in selectedTreeValue.value) {
      selectedItems.value.push(key);
    }

    const showResult = ref(false);
    const isError = ref(false);
    // const isLoading = ref(false);
    const userData = computed(() => store.getters[GET_USERDATA]);

    initData();

    async function initData() {
      await store.dispatch(FETCH_ALL_CATEGORIES);
      await store.dispatch(FETCH_AGENCY);
      //await store.dispatch(FETCH_CATEGORY_PARENTS); called in fetch all action
    }

    async function onSave() {
      agency.value.category = selectedItems.value;
      const data = {
        id: userData.value.organisation_id,
        payload: { category: selectedItems.value },
        routePath: { name: 'agency' }
      };
      emit('submitCat', data);
    }

    return {
      i18n,
      categoryTree,
      agency,
      showResult,
      isError,
      onSave,
      selectedItems
    };
  }
};
</script>

<style lang="scss" scoped>
.loading-btn {
  width: 120px;
  height: 40px;
}
span {
  width: 100%;
}
</style>