<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <form class="text-left" @submit.prevent="onSave">
          <h3 class="font-bold">{{ i18n.$t("Add new contact") }}</h3>
          <div class="form-input">
            <p class="font-semibold">{{ i18n.$t("First Name") }}</p>
            <InputText v-model="contact.first_name" required />
          </div>
          <div class="form-input">
            <p class="font-semibold p-mt-2">{{ i18n.$t("Last Name") }}</p>
            <InputText
              class="border border-teal-500 rounded px-2 py-1"
              v-model="contact.last_name"
              required
            />
          </div>
          <div class="form-input">
            <p class="font-semibold p-mt-2">{{ i18n.$t("Email") }}</p>
            <InputText type="email" v-model="contact.email" required />
          </div>
          <div class="form-input">
            <p class="font-semibold p-mt-2">{{ i18n.$t("Phone") }}</p>
          <input-phone
            v-model="contact.phone" required
            type="text"
            placeholder="Phone Number"
            name="phone"
          />
          </div>
          <input type="hidden" name="owner_id" />
          <div class="form-input p-mt-2">
            <transition-group name="p-messages" tag="div">
              <Message
                v-for="msg of message"
                :severity="msg.severity"
                :key="msg.content"
              >
                {{ msg.content }}
              </Message>
            </transition-group>
          </div>
          <div class="form-input p-mt-4">
            <Button type="submit" class="loading-btn">
              <spinner v-if="isLoading" color="white" />
              <span v-if="!isLoading">{{ i18n.$t("Save") }}</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import axios from '@/plugins/axios';
import Spinner from '@/components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
import ContactModel from '@/models/contact';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Clients', to: '/contacts' },
      { label: 'My Groups', to: '/groups/list' }
    ]
  }),
  components: {
    Spinner
  },
  setup() {
    const { i18n } = useI18n();
    const router = useRouter();
    const contact = reactive(new ContactModel({}));
    const isLoading = ref(false);
    const message = ref([]);

    async function onSave() {
      isLoading.value = true;
      message.value = [];

      const phoneSplit = contact.phone.split(' ');
      contact.prefix = phoneSplit[0];
      contact.phone = phoneSplit[1];

      try {
        await axios.post('contact', contact);
        message.value = [
          { severity: 'success', content: 'Saved successfully' }
        ];
        router.push({ path: `/contacts` });
      } catch (error) {
        console.log('error', error);
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong!' }
        ];
      }
      isLoading.value = false;
    }
    return { i18n, contact, message, isLoading, onSave };
  }
};
</script>

<style scoped>
.form-input {
  width: 100%;
  max-width: 320px;
}
.form-input input {
  width: 100%;
}
.loading-btn {
  width: 100%;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
