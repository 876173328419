<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mb-4"
      />
    </div>
    <div class="p-col-6">
      <h3 class="font-semibold">View Document</h3>
      <div class="doc-tablewrapper">
        <table class="doc-table">
          <tr>
            <td>{{ i18n.$t('Name') }}:&nbsp;</td>
            <td>{{ document.name }}</td>
          </tr>

          <tr>
            <td>{{ i18n.$t('Description') }}:&nbsp;</td>
            <td>
              <div v-html="document.description"></div>
            </td>
          </tr>
          <tr>
            <td>{{ i18n.$t('Type') }}:&nbsp;</td>
            <td>{{ document._type }}</td>
          </tr>
          <tr v-if="document._type != 'template'">
            <td>{{ i18n.$t('File') }}:&nbsp;</td>
            <td>{{ document.fichier }}</td>
          </tr>
          <tr v-if="document._type != 'template'">
            <td>Url:&nbsp;</td>
            <td>{{ document.url }}</td>
          </tr>
          <tr v-if="document.storage">
            <td>Storage:&nbsp;</td>
            <td>{{ document.storage }}</td>
          </tr>
        </table>
      </div>
      <div class="p-pt-2">
        <Button
          icon="pi pi-arrow-left"
          :label="i18n.$t('Cancel')"
          class="p-button-info p-mr-1 "
          style="float: left"
          @click="
            $router.push({
              path: '/document/list'
            })
          "
        />
        <ButtonConfirm @accept="onDelete(document._id)" style="float: left">
          <Button
            icon="pi pi-trash"
            :label="i18n.$t('Delete')"
            class="p-button-danger"
          />
        </ButtonConfirm>
        <Button
          icon="pi pi-pencil"
          :label="i18n.$t('Edit')"
          class="p-button-primary p-ml-1"
          @click="
            $router.push({
              path: '/document/edit',
              query: { document: document._id }
            })
          "
        />
      </div>
    </div>
    <div class="p-col-6 text-center">
      <div
        v-if="document.url && document.url.indexOf('.pdf') == -1"
        style="margin: 0 auto; margin-top: 5px"
      >
        <img
          :src="document.url"
          alt=""
          style="
            max-width: 100%;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
              0 3px 6px rgba(0, 0, 0, 0.23);
          "
        />
      </div>
      <div
        v-if="document.url && document.url.indexOf('.pdf') != -1"
        style="margin: 0 auto; margin-top: 5px"
      >
        <a :href="document.url" target="_blank">
          <Button icon="pi pi-download" label="Download PDF File" class="" />
        </a>
      </div>

      <span v-if="document._type === 'template'">
        <Preview
          :htmlContent="document.contentHtml"
          :cssContent="document.contentCss"
          :jsContent="document.contentJs"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import axios from '@/plugins/axios';
import { useRoute, useRouter } from 'vue-router';
import useI18n from '@/plugins/useI18n';
import Preview from '../../components/Editor/Preview.vue';
import DocumentModel from '@/models/document';
import { API } from '@/plugins/api';
import { FETCH_DOCUMENTS } from '../../store/types';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'Document list', to: '/document/list' }]
  }),
  components: {
    Preview
  },
  setup() {
    const { i18n } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const document = reactive(new DocumentModel({}));
    const contenttemplate = '';
    const htmlContent = ref(``);
    const cssContent = ref(``);
    const jsContent = ref(``);
    const type = ref();
    const types = [
      { name: 'File', code: 'File' },
      { name: 'Template', code: 'Template' }
    ];

    axios.get(`document/${route.query.document}`).then((response) => {
      const data = response.data.data;
      for (const key in data) {
        document[key] = data[key];
      }
      const contenttemplate = JSON.parse(data.content);
      document.contentHtml = contenttemplate.html;
      document.contentCss = contenttemplate.css;
      document.contentJs = contenttemplate.js;
    });
    const api = new API();
    async function onDelete(id) {
      await api.delete('document', id, FETCH_DOCUMENTS);
      router.push('/document/list');
    }
    return {
      i18n,
      document,
      types,
      type,
      htmlContent,
      cssContent,
      jsContent,
      contenttemplate,
      onDelete
    };
  }
};
</script>
<style scoped>
.tablewrapper {
  margin: 1rem 0;
  overflow: auto;
}
.doc-table {
  border-collapse: collapse;
  width: 100%;
  background-color: var(--surface-a);
}
.doc-table td {
  border-bottom: 1px solid var(--surface-d);
  padding: 1rem;
}
.doc-table td:first-child {
  font-family: Courier New, monospace;
  font-weight: 700;
  color: var(--text-color-secondary);
}
</style>