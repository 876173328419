<template>
  <div class="template-preview" v-html="cssContentRendered + htmlContentRendered"></div>
</template>
<script>
import { ref, watch } from 'vue';
export default {
  props: {
    htmlContent: String,
    cssContent: String,
    jsContent: String
  },
  setup(props) {
    const htmlContentRendered = ref(`<main>${props.htmlContent}</main>`);
    const cssContentRendered = ref(`<style>${props.cssContent}</style>`);
    const jsContentRendered = ref(props.jsContent);
    const script = ref(``)
    watch(
      () => [props.htmlContent],
      () => {
        htmlContentRendered.value = `<main>${props.htmlContent}</main>`;
      }
    );
    watch(
      () => [props.cssContent],
      () => {
        cssContentRendered.value = `<style>${props.cssContent}</style>`;
      }
    );
    watch(
      () => [props.jsContent],
      () => {
        var script = document.createElement('script');
        script.append(`${props.jsContent}`);
        script.type = 'text/javascript';
        document.getElementsByClassName("template-preview")[0].append(script);
      }
    ); 
    return { htmlContentRendered, cssContentRendered };
  }
};
</script>
<style lang="scss" scoped>
.template-preview {
  border: 1px solid #bbbbbb;
  border-radius: 15px;
  padding: 15px;
  max-width: 600px;
}
</style>