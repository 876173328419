<template>
  <Breadcrumb
    :home="breadcrumbHome"
    :model="breadcrumbItems"
    class="p-mt-4 p-mb-4"
  />
  <Button
    class="p-ml-3 p-mb-3"
    @click="$router.push({ path: '/document/add' })"
  >
    Add a document
  </Button>
  <DataView
    :value="documents"
    :layout="layout"
    :paginator="true"
    :rows="9"
    :sortOrder="sortOrder"
    :sortField="sortField"
  >
    <template #header>
      <div class="p-grid p-nogutter">
        <div class="p-col-6" style="text-align: left">
          <Dropdown
            v-model="sortKey"
            :options="sortOptions"
            optionLabel="label"
            placeholder="Sort By Date"
            @change="onSortChange($event)"
          />
        </div>
        <div class="p-col-6" style="text-align: right">
          <div class="p-dataview-layout-options p-selectbutton p-buttonset">
            <button
              class="p-button p-button-icon-only"
              :class="{ 'p-highlight': layout === 'list' }"
              type="button"
              @click="layout = 'list'"
            >
              <i class="pi pi-bars"></i>
            </button>
            <button
              class="p-button p-button-icon-only"
              :class="{ 'p-highlight': layout === 'grid' }"
              type="button"
              @click="layout = 'grid'"
            >
              <i class="pi pi-th-large"></i>
            </button>
          </div>
        </div>
      </div>
    </template>
    <template #list="slotProps">
      <div class="p-col-12">
        <div class="product-list-item">
          <div class="product-list-detail">
            <div class="product-name">{{ slotProps.data.name }}</div>
            <div class="product-description">
              {{ slotProps.data.description }}
            </div>
          </div>
          <div class="product-list-detail">
            <div class="product-name">{{ slotProps.data.storage }}</div>
          </div>
          <div class="product-list-action p-align-end">
            <span>
              <Button
                icon="pi pi-bell"
                class="p-button-rounded p-button-warning p-mr-1"
                @click="
                  $router.push({
                    path: '/document/view',
                    query: { document: slotProps.data._id }
                  })
                "
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-1"
                @click="
                  $router.push({
                    path: '/document/edit',
                    query: { document: slotProps.data._id }
                  })
                "
              />
              <ButtonConfirm @accept="onDelete(slotProps.data._id)">
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                />
              </ButtonConfirm>
            </span>
          </div>
        </div>
      </div>
    </template>

    <template #grid="slotProps">
      <div class="p-col-12 p-md-4">
        <div class="product-grid-item card">
          <div class="product-grid-item-top">
            <div>
              <span>
                <i
                  class="pi pi-file-o product-category-icon"
                  v-if="slotProps.data._type !== 'template'"
                />
                <i
                  class="pi pi-info product-category-icon"
                  v-if="slotProps.data._type === 'template'"
                />
              </span>

              <span class="product-category">{{ slotProps.data.name }}</span>
            </div>
            <span>
              <Button
                icon="pi pi-bell"
                class="p-button-rounded p-button-warning p-mr-1"
                @click="
                  $router.push({
                    path: '/document/view',
                    query: { document: slotProps.data._id }
                  })
                "
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-1"
                @click="
                  $router.push({
                    path: '/document/edit',
                    query: { document: slotProps.data._id }
                  })
                "
              />
              <ButtonConfirm @accept="onDelete(slotProps.data._id)">
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                />
              </ButtonConfirm>
            </span>
          </div>
          <div class="product-grid-item-content">
            <div class="product-name">{{ slotProps.data.name }}</div>
            <div class="product-description">
              {{ slotProps.data.description }}
              <br />
              {{ slotProps.data.storage }}
            </div>
          </div>
          <div class="product-grid-item-bottom">
            <span class="product-price">{{ slotProps.data._type }}</span>
          </div>
        </div>
      </div>
    </template>
  </DataView>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { FETCH_DOCUMENTS, GET_DOCUMENTS, GET_USERDATA } from '@/store/types';
import useI18n from '@/plugins/useI18n';
import { API } from '@/plugins/api';
import DataView from 'primevue/dataview';

export default {
  data: () => ({
    layout: 'list',
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [{ label: 'Documents', to: '/document/list' }]
  }),
  components: {
    DataView
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const selectedDate = ref('');
    const documents = computed(() => store.getters[GET_DOCUMENTS]);
    const selected = ref([]);
    const userData = computed(() => store.getters[GET_USERDATA]);
    const sortOptions = ref([
      { label: 'Ascending', value: '!created_at' },
      { label: 'Descending', value: 'created_at' }
    ]);
    const sortKey = ref(null);
    const sortOrder = ref(null);
    const sortField = ref(null);
    const owner_id = userData.value.id;

    function onSortChange(event) {
      const value = event.value.value;
      const sortValue = event.value;
      console.log(sortValue);
      if (value.indexOf('!') === 0) {
        sortOrder.value = -1;
        sortField.value = value.substring(1, value.length);
        sortKey.value = sortValue;
      } else {
        sortOrder.value = 1;
        sortField.value = value;
        sortKey.value = sortValue;
      }
    }

    store.dispatch(FETCH_DOCUMENTS, owner_id);
    const api = new API();

    async function onDelete(id) {
      api.delete('document', id, FETCH_DOCUMENTS);
    }

    function loadHtml(el) {
      console.log(el);
      return el;
    }

    function formatDate(date) {
      let Date = new window.Date(date);
      return (
        Date.getFullYear() + '-' + Date.getMonth() + 1 + '-' + Date.getDate()
      );
    }

    return {
      i18n,
      selected,
      onDelete,
      loadHtml,
      formatDate,
      selectedDate,
      onSortChange,
      sortOptions,
      sortKey,
      sortField,
      sortOrder,
      documents
    };
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
td {
  padding: 5px;
}
.card button {
  margin-left: 8px;
}
</style>

<style lang="scss" scoped>
.p-dropdown {
  width: 14rem;
  font-weight: normal;
}
.product-name {
  font-size: 1.5rem;
  font-weight: 700;
}
.product-description {
  margin: 0 0 1rem 0;
}
.product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}
.product-category {
  font-weight: 600;
  vertical-align: middle;
}
::v-deep(.product-list-item) {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  img {
    width: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
  }
  .product-list-detail {
    flex: 1 1 0;
  }
  .p-rating {
    margin: 0 0 0.5rem 0;
  }
  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-self: flex-end;
  }
  .product-list-action {
    display: flex;
    flex-direction: column;
  }
  .p-button {
    margin-bottom: 0.5rem;
  }
}
::v-deep(.product-grid-item) {
  margin: 0.5rem;
  border: 1px solid #dee2e6;
  .product-grid-item-top,
  .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  img {
    width: 75%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
  }
  .product-grid-item-content {
    text-align: center;
    min-height: 200px;
    height: 200px;
    overflow: hidden;
  }
  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
  }
}
@media screen and (max-width: 576px) {
  .product-list-item {
    flex-direction: column;
    align-items: center;
    img {
      width: 75%;
      margin: 2rem 0;
    }
    .product-list-detail {
      text-align: center;
    }
    .product-price {
      align-self: center;
    }
    .product-list-action {
      display: flex;
      flex-direction: column;
    }
    .product-list-action {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
