<template>
  <div class="p-col-12">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="p-mt-4 p-mb-4"
    />

    <div class="p-grid">
      <div class="p-col">
        <div class="box">
          <h3 class="font-bold">{{ i18n.$t('Edit contact') }}</h3>
          <form @submit.prevent="onSave">
            <div class="form-input">
              <p class="font-semibold">{{ i18n.$t('First Name') }}</p>
              <InputText v-model="contact.first_name" required />
            </div>
            <div class="form-input">
              <p class="font-semibold p-mt-2">{{ i18n.$t('Last Name') }}</p>
              <InputText v-model="contact.last_name" required />
            </div>
            <div class="form-input">
              <p class="font-semibold p-mt-2">{{ i18n.$t('Email') }}</p>
              <InputText type="email" v-model="contact.email" required />
            </div>
            <div class="form-input">
              <p class="font-semibold p-mt-2">{{ i18n.$t('Phone') }}</p>
              <InputText v-model="contact.phone" />
            </div>
            <input type="hidden" name="owner_id" />
            <div class="form-input p-mt-2">
              <transition-group name="p-messages" tag="div">
                <Message
                  v-for="msg of message"
                  :severity="msg.severity"
                  :key="msg.content"
                >
                  {{ msg.content }}
                </Message>
              </transition-group>
            </div>
            <div class="form-input p-mt-4">
              <Button type="submit" class="font-semibold loading-btn">
                <spinner v-if="isLoading" color="white" />
                <span v-if="!isLoading">{{ i18n.$t('Save') }}</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div class="p-col" v-if="groupsId.length > 0">
        <div class="box">
          <assign-group :preSelected="groupsId" class="p-ml-4 p-mt-10" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from '@/plugins/axios';
import AssignGroup from './AssignGroup.vue';
import Spinner from '@/components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
import { FETCH_GROUPS, GET_GROUPS, GET_USERDATA } from '../../store/types';
import ContactModel from '@/models/contact';
import { useStore } from 'vuex';
import { API } from '@/plugins/api';
export default {
  props: {
    preSelected: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Clients', to: '/contacts' },
      { label: 'My Groups', to: '/groups/list' }
    ]
  }),
  components: {
    Spinner,
    AssignGroup
  },

  setup(props) {
    const { i18n } = useI18n();
    const store = useStore();
    const route = useRoute();
    const selected = ref([]);
    const userData = computed(() => store.getters[GET_USERDATA]);

    const groups = computed(() => store.getters[GET_GROUPS]);
    store.dispatch(FETCH_GROUPS, userData.value.id);
    watch(
      () => [props.preSelected],
      () => {
        selected.value = props.preSelected;
      }
    );

    const contact = reactive(new ContactModel({}));
    const groupsId = ref([]);
    const isLoading = ref(false);
    const message = ref([]);

    axios.get(`contact/${route.query.contact}`).then(
      (response) => {
        const contactData = response.data.data.items[0];
        for (const key in contactData) {
          contact[key] = contactData[key];
        }
        if (contactData.__group_id) {
          groupsId.value = [...contactData.__group_id];
        }
      },
      (error) => {
        console.log('[error]', error);
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong!' }
        ];
      }
    );

    const api = new API();
    async function onSave() {
      const id = route.query.contact;
      const routePath = { path: `/contacts` };
      api.update('contact', 'Contact', id, contact, routePath);
    }

    return {
      i18n,
      contact,
      groups,
      message,
      isLoading,
      onSave,
      selected,
      groupsId
    };
  }
};
</script>

<style scoped>
h3 {
  margin-bottom: 0;
}
.form-input {
  width: 100%;
  max-width: 320px;
}
.form-input input {
  width: 100%;
}
.loading-btn {
  height: 40px;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
