import { UserDataInfo } from '@/plugins/api'

const userData = new UserDataInfo();
export default class DocumentModel {
  constructor(item) {
    this.owner_id = userData.ownerId();
    this._id = item._id ? item._id : null;
    this._type = item._type ? item._type : null;
    this._status = item._status ? item._status : null;
    this.name = item.name ? item.name : null;
    this.description = item.description ? item.description : null;
  }
}
