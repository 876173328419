<template>
  <div>
    <div class="p-mt-3">
      <p>{{ i18n.$t('Payments') }}</p>
      <MultiSelect
        name="payments"
        v-model="agency.payments_ids"
        :options="payments"
        optionLabel="value"
        optionValue="id"
        :filter="true"
        @filter="filterPayments"
      />
    </div>
    <div class="p-mt-3">
      <p>{{ i18n.$t('Pratiques') }}</p>
      <MultiSelect
        name="pratiques"
        v-model="agency.pratiques_ids"
        :options="pratiques"
        optionLabel="value"
        optionValue="id"
        :filter="true"
        @filter="filterPratiques"
      />
    </div>
    <div class="p-mt-3" v-if="hasMedicalCategory()">
      <p>{{ i18n.$t('Diplomes') }}</p>
      <MultiSelect
        name="diplomes"
        v-model="agency.diplomes_ids"
        :options="diplomes"
        optionLabel="value"
        optionValue="id"
        :filter="true"
        @filter="filterDiplomes"
      />
    </div>

    <Action @update="onSave" @back="$emit('back')" />
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import { computed, onBeforeMount, ref } from 'vue';
import { GET_BRANDS, FETCH_BRANDS } from '../../../store/types';
import { useStore } from 'vuex';
import Action from './Action';
import axios from '@/plugins/axios';

export default {
  props: {
    agency: {
      type: Object
    }
  },
  components: {
    Action
  },
  setup(props, { emit }) {
    const { i18n } = useI18n();
    const store = useStore();
    const brands = computed(() => store.getters[GET_BRANDS]);

    store.dispatch(FETCH_BRANDS);
    const brand_id = ref([]);

    if (props.agency.brand_id) {
      brand_id.value = props.agency.brand_id;
    }

    const parentCategories = computed(() => {
      const agency = props.agency; //store.getters[GET_AGENCY_MODEL];
      return (
        (agency &&
          agency.category &&
          agency.category.map((v) => v.parent_id)) ||
        []
      );
    });
    function hasMedicalCategory() {
      //5f678c309d25457be468fbb3 -> Medical
      //5f678cba58876325f220971d -> professionnel de santé
      //5f79e7cfa7e84056da0abcf7 -> établissement de soins
      return (
        parentCategories.value.includes('5f678c309d25457be468fbb3') ||
        parentCategories.value.includes('5f678cba58876325f220971d') ||
        parentCategories.value.includes('5f79e7cfa7e84056da0abcf7')
      );
    }
    function onSave() {
      props.agency.brand_id = brand_id.value;
      emit('submit', props.agency);
    }

    let timer = null;
    let timer1 = null;
    let timer2 = null;
    const payments = ref([]);
    const pratiques = ref([]);
    const diplomes = ref([]);

    async function filterPayments(event) {
      if (timer != null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        console.log(event.value, props.agency.payments_ids);
        timer = null;
        const ids =
          typeof props.agency.payments_ids != 'undefined'
            ? props.agency.payments_ids
            : '';
        axios
          .get(`data/payments?q=${event.value}` + '&ids=' + ids)
          .then(function (res) {
            payments.value = res.data.data.items;
          });
      }, 1500);
    }

    async function filterPratiques(event) {
      if (timer != null) {
        clearTimeout(timer1);
      }
      timer1 = setTimeout(function () {
        console.log(event.value, props.agency.pratiques_ids);
        timer = null;
        const ids =
          typeof props.agency.pratiques_ids != 'undefined'
            ? props.agency.pratiques_ids
            : '';
        axios
          .get(`data/pratiques?q=${event.value}` + '&ids=' + ids)
          .then(function (res) {
            pratiques.value = res.data.data.items;
          });
      }, 1500);
    }

    async function filterDiplomes(event) {
      if (timer2 != null) {
        clearTimeout(timer);
      }
      timer2 = setTimeout(function () {
        //console.log(event.value, props.agency.diplomes_ids);
        timer = null;
        const ids =
          typeof props.agency.diplomes_ids != 'undefined'
            ? props.agency.diplomes_ids
            : '';
        axios
          .get(`data/diplomes?q=${event.value}` + '&ids=' + ids)
          .then(function (res) {
            diplomes.value = [];
            res.data.data.items.forEach((el) => {
              el.value = el.value.substring(0, 150);
              diplomes.value.push(el);
            });
          });
      }, 1500);
    }
    filterPayments({ value: '' });
    filterPratiques({ value: '' });

    if (hasMedicalCategory()) {
      filterDiplomes({ value: '' });
    }

    return {
      onSave,
      brands,
      i18n,
      brand_id,
      filterPayments,
      filterPratiques,
      filterDiplomes,
      payments,
      pratiques,
      diplomes,
      hasMedicalCategory
    };
  }
};
</script>