<template>
  <form class="text-left mt-4">
    <div class="p-d-flex p-flex-column p-flex-md-row">
      <div class="p-mb-2 p-mr-2 p-col-md-4">
        <div class="form-input">
          <p class="font-semibold text">{{ i18n.$t('Name') }}</p>
          <InputGroup
            :invalid="errors.name"
            :validation="valid.name"
            :text="true"
          >
            <InputText
              v-model="agency.name"
              name="name"
              class="border border-teal-500 rounded p-1"
              @blur="(valid.name = true), validation()"
            />
          </InputGroup>
        </div>
        <div class="form-input logo-wrapper">
          <p class="font-semibold text p-mt-2">{{ i18n.$t('Logo') }}</p>
          <img
            class="logo"
            :src="logo ? logo.objectURL : agency.logo"
            v-if="loadLogo(agency.logo) || logo"
          />
          <LogoUpload @uploadLogo="logo = $event" @clear="logo = null" />
        </div><!-- 
        <div class="form-input">
          <p class="font-semibold text p-mt-2">
            {{ i18n.$t('Description') }}
          </p>

          <Editor
            v-model="agency.description"
            editorStyle="height: 450px"
            @input="(valid.description = true), validation()"
          >
          </Editor>
        </div> -->
        <div
          class="form-input address"
          @click="showSelectAddress = !showSelectAddress"
        >
          <div class="select">
            <p class="font-semibold text p-mt-2">
              {{ i18n.$t('Address') }}
            </p>
            <InputGroup
              :invalid="errors.address"
              :validation="valid.address"
              :text="true"
            >
              <InputText
                v-model="agency.address"
                name="address"
                class="border border-teal-500 rounded p-1"
                @blur="(valid.address = true), validation()"
              />
            </InputGroup>
            <div class="overlay"></div>
            <i class="pi pi-arrow-right" v-if="!valid"></i>
          </div>
        </div>
        <SelectAddress
          class="select-address form-input"
          :address="address"
          :country="country"
          v-if="showSelectAddress"
          @selected="agency.address = $event"
        />
      </div>
      <div class="p-mb-2 p-mr-2">
        <div class="form-input">
          <p class="font-semibold text">{{ i18n.$t('Email') }}</p>
          <InputGroup
            :invalid="errors.email"
            :validation="valid.email"
            :text="true"
          >
            <InputText
              v-model="agency.email"
              name="email"
              class="border border-teal-500 rounded p-1"
              @blur="(valid.email = true), validation()"
            />
          </InputGroup>
        </div>
        <div class="form-input">
          <p class="font-semibold text p-mt-2">
            {{ i18n.$t('Website') }}
          </p>
          <InputGroup
            :invalid="errors.website"
            :validation="valid.website"
            :text="true"
          >
            <InputText
              v-model="agency.website"
              name="website"
              class="border border-teal-500 rounded p-1"
              @blur="(valid.website = true), validation()"
            />
          </InputGroup>
        </div>
        <div class="form-input">
          <p class="font-semibold text p-mt-2">
            {{ i18n.$t('Telephone') }}
          </p>
          <InputGroup
            :invalid="errors.phone"
            :validation="valid.phone"
            class="phone-select"
            :text="true"
          >
            <input-phone
              v-model="agency.phone"
              type="text"
              placeholder="Phone Number"
              name="phone"
              @onBlur="(valid.phone = true), validation()"
            />
          </InputGroup>
        </div>
        <div class="flex p-mt-3 items-center">
          <Currency
            :item="agency.currency"
            @onBlur="(valid.currency = true), validation()"
            @select="agency.currency = $event"
          />
        </div>
      </div>
    </div>
    <div v-if="showResult" class="form-input p-mt-2">
      <p v-if="isError" class="font-semibold text-red-600">
        {{ i18n.$t('Oops, something went wrong') }}
      </p>
      <p v-if="!isError" class="font-semibold text-green-600">
        {{ i18n.$t('Saved successfully') }}
      </p>
    </div>
    <Action @update="validation" @back="$emit('back')"/>
  </form>
</template>

<script>
import { ref, reactive } from 'vue';

import Spinner from '@/components/UI/Spinner.vue';
import FileInput from '@/components/UI/FileInput.vue';
import SelectAddress from '@/components/Register/SelectAddress.vue';
import useI18n from '@/plugins/useI18n';
import * as yup from 'yup';
import InputGroup from '@/components/UI/InputGroup';
import LogoUpload from '@/components/Register/LogoUpload';
import { loadLogo } from '@/plugins/utility.js';
import Currency from '@/components/UI/Currency.vue';
import Action from './Action.vue';
export default {
  props: {
    agency: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    showResult: {
      type: Boolean
    },
    isError: {
      type: Boolean
    }
  },
  components: {
    Spinner,
    FileInput,
    SelectAddress,
    InputGroup,
    Currency,
    LogoUpload,
    Action
  },
  setup(props, { emit }) {
    const { i18n } = useI18n();
      console.log(props.agency)

    const logo = ref(null);

    const showSelectAddress = ref(false);
    const country = ref('');

    const address = reactive({
      addressReq: '',
      fullAddress: ''
    });

    const valid = ref({
      email: false,
      name: false,
      phone: false,
      address: false
    });

    const errors = ref({});

    let schema = yup.object().shape({
      email: yup.string().email().required(),
      name: yup.string().min(3).required(),
      phone: yup.string().min(9).required(),
      address: yup.string().min(3).required()
    });

    function validation(click) {
      const item = props.agency;
      schema
        .validate(
          {
            email: item.email,
            name: item.name,
            phone: item.phone ? item.phone.replace(/\s+/g, '') : '',
            address: item.address
          },
          { abortEarly: false }
        )
        .then(() => {
          errors.value = {};
          if (click) {
            turnValid();
            onSave();

            setTimeout(() => {
              clearValid();
            }, 3000);
          }
        })
        .catch((err) => {
          errors.value = {};
          console.log(err);
          err.inner.forEach((el) => {
            errors.value[el.path] = true;
          });

          if (click) {
            turnValid();
          }
        });
    }

    function turnValid() {
      for (let key in valid.value) {
        valid.value[key] = true;
      }
    }

    function clearValid() {
      valid.value = {
        email: false,
        name: false,
        phone: false,
        address: false
      };
      props.showResult = false;
    }

    function onSave() {
      if (typeof logo.value !== 'string') {
        props.agency.logo = logo.value;
      } else {
        props.agency.logo = '';
      }
      emit('submit', props.agency);
    }

    return {
      i18n,
      onSave,
      address,
      country,
      showSelectAddress,
      valid,
      validation,
      errors,
      logo,
      loadLogo
    };
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
}
.form-input input {
  width: 100%;
}
span {
  width: 100%;
}

.logo {
  max-width: 50%;
  width: auto;
}
.address {
  .select {
    width: 100%;
    cursor: pointer;
    position: relative;
    input {
      padding-right: 30px;
    }
    .overlay {
      width: 100%;
      height: 100%;
      z-index: 1000;
      position: absolute;
      top: 0;
    }
    i {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(50%);
    }
  }
}
</style>