<template>
  <div class="p-grid p-fluid">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="p-mt-4 p-mb-4"
    />
    <div class="p-col-12">
      <h3 class="font-bold text-3xl text-left">
        {{ i18n.$t('My agency') }} : {{ agency.name }}
      </h3>
      <TabView :activeIndex="currentTab" @tab-click="currentTab = $event.index">
        <TabPanel :header="i18n.$t('Principal')">
          {{ i18n.$t('Id') }}: {{ agency._id }} <br />
          {{ i18n.$t('Name') }}: {{ agency.name }} <br />
          {{ i18n.$t('Currency') }}: {{ agency.currency }} <br />
          {{ i18n.$t('Address') }} : {{ agency.address }} <br />
          {{ i18n.$t('Email') }} : {{ agency.email }} <br />
          {{ i18n.$t('Telephone') }} : {{ agency.phone }} <br />
          {{ i18n.$t('Website') }} : {{ agency.website }} <br />
          <span class="logo-wrapper">
            {{ i18n.$t('Logo') }} :
            <img
              class="logo"
              :src="agency.logo"
              :alt="agency.logo"
              v-if="loadLogo(agency.logo)"
          /></span>
          {{ i18n.$t('Video') }} : {{ agency.video }}
        </TabPanel>
        <template v-if="can('v1.organisation.category')">
          <TabPanel :header="i18n.$t('Category')">
            <template v-if="agency.category.length > 0">
              <li v-for="cat in agency.category" :key="cat.id" class="px-4">
                {{ cat.name }}
              </li>
            </template>
            <div v-else>{{ i18n.$t('Categories') }}: 0</div>
          </TabPanel>
        </template>
        <template>
          <TabPanel :header="i18n.$t('Prestations')">
            <b>{{ i18n.$t('Brands') }}: </b>
            <div v-if="selectBrands.length > 0">
              <li v-for="item in selectBrands" :key="item.id" class="px-4">
                {{ item.name }}
              </li>
            </div>
            <span v-else>_</span>
            <hr />
            <div>
              <b>{{ i18n.$t('Tariffs') }}: </b>
              <div v-if="agency.tariffs && agency.tariffs.length > 0">
                <li v-for="item in agency.tariffs" :key="item.id" class="px-4">
                  {{ item.value }}
                </li>
              </div>
              <span v-else>_</span>
              <hr />
            </div>
            <div>
              <b>{{ i18n.$t('Skills') }}: </b>
              <div v-if="agency.skills && agency.skills.length > 0">
                <li v-for="item in agency.skills" :key="item.id" class="px-4">
                  {{ item.value }}
                </li>
              </div>
              <span v-else>_</span>
            </div>
          </TabPanel>
        </template>
        <template>
          <TabPanel :header="i18n.$t('Pratiques')">
            <b>{{ i18n.$t('Payments') }}: </b>
            <div v-if="agency.payments && agency.payments.length > 0">
              <li v-for="item in agency.payments" :key="item.id" class="px-4">
                {{ item.value }}
              </li>
            </div>
            <span v-else>_</span>
            <hr />
            <b>{{ i18n.$t('Pratiques') }}: </b>
            <div v-if="agency.pratiques && agency.pratiques.length > 0">
              <li v-for="item in agency.pratiques" :key="item.id" class="px-4">
                {{ item.value }}
              </li>
            </div>
            <span v-else>_</span>
            <hr />

            <b>{{ i18n.$t('Diplomes') }}: </b>
            <div v-if="agency.pratiques && agency.pratiques.length > 0">
              <li v-for="item in agency.diplomes" :key="item.id" class="px-4">
                {{ item.value }}
              </li>
            </div>
            <span v-else>_</span>
          </TabPanel>
        </template>
        <template>
          <TabPanel :header="i18n.$t('Details')">
            <div v-html="agency.description"></div>
          </TabPanel>
        </template>
      </TabView>
      <div class="p-col-2 p-grid">
        <Button
          class="
            bg-transparent
            hover:bg-teal-500
            font-semibold
            hover:text-white
            py-2
            px-4
            border border-teal-500
            hover:border-transparent
            rounded
            float-right
            p-mt-4 p-mr-4
          "
          @click="onEditAgency"
          v-if="can('v1.organisation.update')"
        >
          {{ i18n.$t('Update') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import {
  FETCH_AGENCY,
  GET_AGENCY_MODEL,
  FETCH_BRANDS,
  GET_BRANDS
} from '../../store/types';
import useI18n from '@/plugins/useI18n';
import { loadLogo } from '@/plugins/utility.js';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'my agency', to: '/agency' }]
  }),
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    store.dispatch(FETCH_BRANDS);
    store.dispatch(FETCH_AGENCY);
    const agency = computed(() => store.getters[GET_AGENCY_MODEL]);
    const brands = computed(() => store.getters[GET_BRANDS]);
    const selectBrands = agency.value.brand_id
      ? brands.value.filter((el) => agency.value.brand_id.includes(el._id))
      : [];
    const paramsCurrentTab = route.params.currentTab
      ? parseInt(route.params.currentTab)
      : 0;
    const currentTab = ref(paramsCurrentTab);
    console.log(currentTab);
    function onEditAgency(e) {
      e.preventDefault();
      router.push({
        name: 'agency-edit',
        params: { currentTab: currentTab.value }
      });
    }

    return {
      i18n,
      agency,
      onEditAgency,
      selectBrands,
      loadLogo,
      currentTab
    };
  }
};
</script>

<style lang="scss">
.form-input {
  width: 100%;
  max-width: 320px;
  display: flex;
}
.form-input input {
  width: 100%;
}
button {
  justify-content: center;
}
.logo-wrapper {
  display: flex;
  flex-direction: column;
  .logo {
    width: 150px;
  }
}
.p-button {
  min-height: 40px;
  min-width: 120px;
  &.p-button-icon-only {
    min-height: auto;
    min-width: auto;
  }
}
</style>
