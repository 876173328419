<template>
  <TabView>
    <TabPanel header="HTML">
      <HTMLEditor
        :content="htmlContent"
        v-model="htmlContent"
        :onSaved="onSave"
      />
    </TabPanel>
    <TabPanel header="CSS">
      <CSSEditor :content="cssContent" v-model="cssContent" :onSaved="onSave" />
    </TabPanel>
    <TabPanel header="JS">
      <JSEditor :content="jsContent" v-model="jsContent" :onSaved="onSave" />
    </TabPanel>
    <TabPanel header="Preview">
      <Preview
        :htmlContent="htmlContent"
        :cssContent="cssContent"
        :jsContent="jsContent"
      />
    </TabPanel>
  </TabView>
</template>

<script>
import { computed, ref } from 'vue';
import ContentEditor from '@/components/Editor/ContentEditor.vue';
import CSSEditor from '@/components/Editor/CSSEditor.vue';
import HTMLEditor from '@/components/Editor/HTMLeditor.vue';
import JSEditor from '@/components/Editor/JSeditor.vue';
import Preview from '@/components/Editor/Preview.vue';
import useI18n from '@/plugins/useI18n';
import { template } from '@babel/core';
export default {
  components: {
    ContentEditor,
    CSSEditor,
    Preview,
    HTMLEditor,
    JSEditor
  },
  methods: {
    onSave() {
      this.template = {
        html: this.htmlContent,
        css: this.cssContent,
        js: this.jsContent
      };
      this.$emit('update:modelValue', this.template);
    }
  },
  props: ['template'],
  setup(props) {
    const { i18n } = useI18n();
    const template = ref('');
    const htmlContent = ref(props.template.html);
    const cssContent = ref(props.template.css);
    const jsContent = ref(props.template.js);
    return { i18n, htmlContent, cssContent, jsContent, template };
  }
};
</script>