<template>
  <div class="p-grid">
    <div class="p-col-12">
      <h3>{{ titleReturn }}</h3>
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <div>
          <Button
            class="p-mr-2"
            @click="$router.push({ path: '/contacts/add' })"
            v-if="can('v1.contact.create')"
          >
            {{ i18n.$t('Add a contact') }}
          </Button>
          <Button
            class="bg-teal-500 text-white rounded p-mr-2"
            @click="$router.push({ path: '/groups/list' })"
          >
            {{ i18n.$t('Group list') }}
          </Button>
          <Button @click="onMultiAssignGroup" :disabled="selected.length === 0">
            {{ i18n.$t('Multi Assign Group') }}
          </Button>
        </div>
        <DataTable
          :value="contacts"
          dataKey="_id"
          :rowHover="true"
          class="apin-data-table p-mt-2"
          v-model:selection="selected"
        >
          <template #header>
            <div class="table-header">{{ i18n.$t('List of Contact') }}</div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
          <Column header="Name">
            <template #body="slotProps">
              <span
                >{{ slotProps.data.first_name }}&nbsp;{{
                  slotProps.data.last_name
                }}
                {{ slotProps.data.exist }}</span
              >
            </template>
          </Column>
          <Column header="Groups">
            <template #body="slotProps">
              <ul class="groups-in-table">
                <li
                  v-for="group in slotProps.data.__group_id"
                  :key="group"
                  class="px-4"
                >
                  {{ displayGroupName(group) }}
                </li>
              </ul>
            </template>
          </Column>
          <Column header="Apin">
            <template #body="slotProps">
              <span v-if="slotProps.data.exist == true"
                ><i class="pi pi-check"></i
              ></span>
            </template>
          </Column>
          <Column field="phone" header="Phone"></Column>
          <Column field="email" header="Email"></Column>
          <Column :exportable="false">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                v-if="can('v1.contact.update')"
                @click="
                  $router.push({
                    path: '/contacts/edit',
                    query: {
                      contact: slotProps.data._id
                    }
                  })
                "
              />
              <ButtonConfirm
                @accept="onDelete(slotProps.data._id)"
                v-if="can('v1.contact.delete')"
              >
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                />
              </ButtonConfirm>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import {
  FETCH_CONTACTS,
  FETCH_GROUPS,
  GET_CONTACTS,
  GET_GROUPS,
  SET_SELECTED_CONTACTS,
  GET_USERDATA
} from '@/store/types';
import useI18n from '@/plugins/useI18n';
import { API } from '@/plugins/api';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Clients', to: '/contacts' },
      { label: 'My Groups', to: '/groups/list' }
    ]
  }),
  computed: {
    titleReturn() {
      if (this.$route.query._type == 'subscribers') {
        return 'Subscribers list';
      } else if (this.$route.query._type == 'contacts') {
        return 'Contacts list';
      }
    }
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const router = useRouter();
    const contacts = computed(() => store.getters[GET_CONTACTS]);
    const groups = computed(() => store.getters[GET_GROUPS]);
    const userData = computed(() => store.getters[GET_USERDATA]);

    const selected = ref([]);

    initData();

    async function initData() {
      await store.dispatch(FETCH_GROUPS, userData.value.id);
      await store.dispatch(FETCH_CONTACTS, userData.value.id);
    }

    function displayGroupName(groupId) {
      const group = _.find(groups.value, function (o) {
        return o._id === groupId;
      });
      return group ? group.name : 'unknown';
    }

    async function onMultiAssignGroup() {
      await store.commit(SET_SELECTED_CONTACTS, selected.value);
      let ids = [];
      selected.value.forEach((v) => ids.push(v._id));
      router.push({
        path: '/contacts/multi-assign-group',
        query: { contact: ids.join(',') }
      });
    }
    const api = new API();

    async function onDelete(id) {
      api.delete('contact', id, FETCH_CONTACTS);
    }
    return {
      i18n,
      contacts,
      groups,
      selected,
      onMultiAssignGroup,
      displayGroupName,
      onDelete
    };
  }
};
</script>

<style lang="scss" scoped>
button:disabled {
  background: #cccccc;
}
.groups-in-table {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  li {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
