<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <h3 class="font-semibold">Add Document</h3>
    </div>
    <div class="p-col-6">
      <div class="card">
        <form
          ref="documentform"
          enctype="multipart/form-data"
          @submit="onSubmit"
        >
          <div class="form-input">
            <label for="name" class="mr-4 font-semibold">Name:</label>
            <InputText
              type="name"
              name="name"
              v-model="name"
              :class="{ 'p-error': !!errors.name }"
            />
          </div>
          <div class="form-input">
            <label for="description" class="mr-4 font-semibold"
              >Description:</label
            >
            <QuillEditor
              :class="{ 'p-error': !!errors.description }"
              toolbar="minimal"
              v-model:content="description"
              :content="description"
              contentType="html"
            />
          </div>
          <div class="form-input">
            <label for="type" class="mr-4 font-semibold">Type:</label>
            <Dropdown
              name="type"
              v-model="type"
              :options="types"
              optionLabel="name"
              optionValue="code"
              :class="{ 'p-error': !!errors.type }"
            />
            <input type="hidden" name="_type" v-model="type" />
          </div>
          <div class="form-input">
            <label for="category" class="mr-4 font-semibold">Category:</label>
            <Dropdown
              name="category"
              v-model="category"
              :options="categories"
              optionLabel="name"
              optionValue="code"
              :class="{ 'p-error': !!errors.type }"
            />
            <input type="hidden" name="category" v-model="category" />
          </div>
          <div class="form-input" v-if="type == 'file' || type == undefined">
            <FileUpload
              auto
              name="docupload"
              :disabled="uploadDisabled"
              :customUpload="true"
              @uploader="onFileUpload"
              @remove="onFileRemove"
              :accept="`image/*,application/pdf`"
              :maxFileSize="9000000"
              :showUploadButton="false"
              :uploadLabel="uploadLabel"
              :multiple="false"
            >
              <template #empty>
                <p>Drag and drop files to here to upload.</p>
              </template>
            </FileUpload>
          </div>
          <input type="hidden" name="owner_id" v-model="owner_id" />
          <input
            type="hidden"
            name="organisation_id"
            v-model="organisation_id"
          />
          <Button
            label="Cancel"
            class="p-button-outlined p-button-danger p-mr-2"
            @click="$router.push('/document/list')"
          />
          <Button type="Save document" ref="submitBtn" class="p-mt-3">
            Submit
          </Button>
          <div v-if="showResult" class="mt-4">
            <p v-if="isError" class="font-semibold text-red-600">
              Oops, something went wrong!
            </p>
            <p v-if="!isError" class="font-semibold text-green-600">
              Saved successfully
            </p>
          </div>
        </form>
      </div>
    </div>
    <div class="p-col-6">
      <template v-if="type === 'template'">
        <template-editor v-model="template" />
      </template>
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import axios from '@/plugins/axios';
import FileUpload from 'primevue/fileupload';
import TemplateEditor from './TemplateEditor';
import useI18n from '@/plugins/useI18n';
import { GET_USERDATA } from '@/store/types';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill';

export default {
  name: 'MyInput',
  components: {
    FileUpload,
    TemplateEditor,
    QuillEditor
  },
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [{ label: 'Document', to: '/document/list' }]
  }),
  setup() {
    const { i18n } = useI18n();
    const router = useRouter();
    const store = useStore();
    const documentform = ref();
    const submitBtn = ref();
    const template = ref(``);
    const showResult = ref(false);
    const isError = ref(false);
    const uploadDisabled = ref(false);
    const uploadLabel = ref('Upload');
    const userData = computed(() => store.getters[GET_USERDATA]);

    const owner_id = userData.value.id;
    const organisation_id = userData.value.organisation_id;
    let selectedFiles = null;
    const isDisabled = ref(true);
    const types = [
      { name: 'Template', code: 'template' },
      { name: 'File', code: 'file' }
    ];
    const categories = [
      { name: 'Information', code: 'information' },
      { name: 'Services', code: 'services' }
    ];
    const { handleSubmit, errors } = useForm({
      validationSchema: yup.object().shape({
        name: yup.string().required(),
        description: yup.string().required(),
        type: yup.string().required(),
        category: yup.string().required()
      })
    });
    const { value: name } = useField('name', yup.string().required());
    const { value: description } = useField(
      'description',
      yup.string().required()
    );
    description.value = '<p></p>';
    const { value: type } = useField('type', yup.string().required());
    const { value: category } = useField('category', yup.string().required());

    const onSubmit = handleSubmit(async () => {
      const formdata = new FormData(documentform.value);

      formdata.append('file', selectedFiles);
      formdata.append('content', JSON.stringify(template.value));

      showResult.value = false;
      uploadLabel.value = 'Uploading...';
      uploadDisabled.value = true;
      try {
        await axios.post(`document`, formdata);
        isError.value = false;
        router.push({ path: `/document/list` });
      } catch (error) {
        console.log('[error]', error);
        isError.value = true;
      }
      showResult.value = true;
      uploadDisabled.value = false;
      uploadLabel.value = 'Upload';
    });

    function onFileUpload(event) {
      //console.log(event.files.length);
      selectedFiles = event.files[0];
      if (event.files.length > 0) {
        isDisabled.value = false;
        uploadDisabled.value = true;
      } else {
        uploadDisabled.value = false;
      }
    }
    function onFileRemove(event) {
      //console.log(event.files.length);
      if (event.files.length > 0) {
        uploadDisabled.value = true;
      } else {
        uploadDisabled.value = false;
      }
    }

    return {
      i18n,
      documentform,
      submitBtn,
      name,
      description,
      type,
      types,
      category,
      categories,
      errors,
      owner_id,
      organisation_id,
      onFileUpload,
      onFileRemove,
      onSubmit,
      uploadDisabled,
      uploadLabel,
      showResult,
      isError,
      template,
      isDisabled
    };
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  margin-top: 20px;
}
.form-action {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.form-action button {
  height: 40px;
}
.add-btn {
  width: 120px;
}
</style>
