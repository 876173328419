<template>
  <div>
    <div v-if="hasBeautyCategory() && can('v1.brand.update')">
      <p>{{ i18n.$t('Select Brand') }}</p>

      <MultiSelect
        name="brand"
        v-model="brand_id"
        :options="brands"
        optionLabel="name"
        optionValue="_id"
      />
    </div>

    <div class="p-mt-3" v-if="hasMedicalCategory()">
      <p>{{ i18n.$t('Skills') }}</p>
      <MultiSelect
        name="Skills"
        v-model="agency.skills_ids"
        :options="skills"
        optionLabel="value"
        optionValue="id"
        :filter="true"
        @filter="filterSkills"
      />
    </div>

    <div class="p-mt-3" v-if="hasMedicalCategory()">
      <p>{{ i18n.$t('Tariffs') }}</p>
      <MultiSelect
        name="tariffs"
        v-model="agency.tariffs_ids"
        :options="tariffs"
        optionLabel="value"
        optionValue="id"
        :filter="true"
        @filter="filterTariffs"
      />
    </div>
    <Action @update="onSave" @back="$emit('back')" />
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import { computed, ref } from 'vue';
import {
  GET_BRANDS,
  FETCH_BRANDS,
  GET_AGENCY_MODEL
} from '../../../store/types';
import { useStore } from 'vuex';
import Action from './Action';
import axios from '@/plugins/axios';

export default {
  props: {
    agency: {
      type: Object
    }
  },
  components: {
    Action
  },
  setup(props, { emit }) {
    const { i18n } = useI18n();
    const store = useStore();
    const brands = computed(() => store.getters[GET_BRANDS]);
    const parentCategories = computed(() => {
      const agency = props.agency; //store.getters[GET_AGENCY_MODEL];
      return (
        (agency &&
          agency.category &&
          agency.category.map((v) => v.parent_id)) ||
        []
      );
    });
    store.dispatch(FETCH_BRANDS);
    const brand_id = ref([]);

    if (props.agency.brand_id) {
      brand_id.value = props.agency.brand_id;
    }

    function onSave() {
      props.agency.brand_id = brand_id.value;
      emit('submit', props.agency);
    }

    let timer = null;
    let timer1 = null;
    const tariffs = ref([]);
    const skills = ref([]);

    function hasBeautyCategory() {
      //5f79eb73fc4c1b520d315a35 // Beauty
      return parentCategories.value.includes('5f79eb73fc4c1b520d315a35');
    }
    function hasMedicalCategory() {
      //5f678c309d25457be468fbb3 -> Medical
      //5f678cba58876325f220971d -> professionnel de santé
      //5f79e7cfa7e84056da0abcf7 -> établissement de soins
      return (
        parentCategories.value.includes('5f678c309d25457be468fbb3') ||
        parentCategories.value.includes('5f678cba58876325f220971d') ||
        parentCategories.value.includes('5f79e7cfa7e84056da0abcf7')
      );
    }

    async function filterTariffs(event) {
      if (timer != null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        console.log(event.value, props.agency.tariffs_ids);
        timer = null;
        const ids =
          typeof props.agency.tariffs_ids != 'undefined'
            ? props.agency.tariffs_ids
            : '';
        axios
          .get(`data/tariffs?q=${event.value}` + '&ids=' + ids)
          .then(function (res) {
            tariffs.value = res.data.data.items;
          });
      }, 1500);
    }

    async function filterSkills(event) {
      if (timer != null) {
        clearTimeout(timer1);
      }
      timer1 = setTimeout(function () {
        console.log(event.value, props.agency.skills_ids);
        timer = null;
        const ids =
          typeof props.agency.skills_ids != 'undefined'
            ? props.agency.skills_ids
            : '';
        axios
          .get(`data/skills?q=${event.value}` + '&ids=' + ids)
          .then(function (res) {
            skills.value = res.data.data.items;
          });
      }, 1500);
    }
    if (hasMedicalCategory()) {
      filterTariffs({ value: '' });
      filterSkills({ value: '' });
    }
    return {
      onSave,
      brands,
      i18n,
      brand_id,
      tariffs,
      skills,
      filterTariffs,
      filterSkills,
      hasBeautyCategory,
      hasMedicalCategory
    };
  }
};
</script>