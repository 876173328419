<template>
  <form class="text-left mt-4">
    <div class="p-d-flex p-flex-column p-flex-md-row">
      <div class="p-mb-2 p-mr-2 p-col-12">
        <div class="form-input" style="max-width: 100%">
          <p class="font-semibold text p-mt-2">
            {{ i18n.$t('Description') }}
          </p>
          <QuillEditor
            toolbar="minimal"
            ref="qEditor"
            v-model:content="description"
            :content="description"
            contentType="html"
            @ready="quillReady"
          />
        </div>
      </div>
    </div>
    <div v-if="showResult" class="form-input p-mt-2">
      <p v-if="isError" class="font-semibold text-red-600">
        {{ i18n.$t('Oops, something went wrong') }}
      </p>
      <p v-if="!isError" class="font-semibold text-green-600">
        {{ i18n.$t('Saved successfully') }}
      </p>
    </div>
    <Action @update="validation" @back="$emit('back')" />
  </form>
</template>

<script>
import { ref } from 'vue';

import useI18n from '@/plugins/useI18n';
import * as yup from 'yup';
import Action from './Action.vue';
import { QuillEditor } from '@vueup/vue-quill';

export default {
  props: {
    agency: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    showResult: {
      type: Boolean
    },
    isError: {
      type: Boolean
    }
  },
  components: {
    Action,
    QuillEditor
  },
  setup(props, { emit }) {
    const { i18n } = useI18n();
    const description = ref('');
    let quill = null;
    try {
      description.value = props.agency.description;
    } catch {
      description.value = '';
    }
    const valid = ref({
      description: true
    });

    const errors = ref({});

    let schema = yup.object().shape({
      description: yup.string().min(3)
    });

    function validation(click) {
      errors.value = {};
      if (click) {
        turnValid();
        props.agency.description = quill.root.innerHTML;
        onSave();

        setTimeout(() => {
          clearValid();
        }, 3000);
      }
    }

    function turnValid() {
      for (let key in valid.value) {
        valid.value[key] = true;
      }
    }

    function clearValid() {
      props.showResult = false;
    }

    function onSave() {
      emit('submit', props.agency);
    }
    function quillReady(e) {
      quill = e;
    }

    return {
      i18n,
      onSave,
      valid,
      validation,
      errors,
      description,
      quillReady
    };
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
}
.form-input input {
  width: 100%;
}
span {
  width: 100%;
}

.logo {
  max-width: 50%;
  width: auto;
}
.address {
  .select {
    width: 100%;
    cursor: pointer;
    position: relative;
    input {
      padding-right: 30px;
    }
    .overlay {
      width: 100%;
      height: 100%;
      z-index: 1000;
      position: absolute;
      top: 0;
    }
    i {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(50%);
    }
  }
}
</style>